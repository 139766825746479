

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule, Hotel, hotelConverter } from '@/store/app'
import { ValidationObserver } from 'vee-validate';
import firebase from 'firebase'
import 'firebase/firestore';
import fs = firebase.firestore;
const db = firebase.firestore();

import BaseInput from '@/components/BaseInput.vue'
import BaseInputFile from '@/components/BaseInputFile.vue'
import BaseInputTextarea from '@/components/BaseInputTextarea.vue'
import BaseButton from '@/components/BaseButton.vue'
import Panel from '@/components/Panel.vue'

import { zipToAddress } from '@/zipToAddress'
@Component({
  components: {
    BaseInput,
    BaseInputFile,
    BaseInputTextarea,
    BaseButton,
    ValidationObserver,
    Panel
  },
})
export default class OperatorSetting extends Vue {
  private image: File = {} as File;
  private previewImage = '';
  private currentImage = '';
  private logo: File = {} as File;
  private previewLogo = '';
  private currentLogo = '';
  private get hotel() {
    return AppModule.hotel;
  }
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  mounted() {
    firebase.auth().onAuthStateChanged(user => {
      AppModule.setCurrentUser(user);
      if (user) {
        // db.collection('users').doc(user.uid).set({}, {merge: true});
        db.collection("hotels").doc(user.uid).set({user: db.collection('users').doc(user.uid)}, {merge: true});
        db.collection("hotels").doc(user.uid).withConverter(hotelConverter).get().then((hotel)=>{
          AppModule.setHotel(hotel.data()!);
          firebase.storage().ref().child(`/hotels/${hotel.id}/${hotel.id}`).getDownloadURL().then((v)=>{
            this.currentImage = v;
          }).catch((e)=>{
            this.currentImage = require('@/assets/no-image.png');
          });
          firebase.storage().ref().child(`/hotels/${hotel.id}/logo`).getDownloadURL().then((v)=>{
            this.currentLogo = v;
          }).catch((e)=>{
            this.currentLogo = require('@/assets/no-image.png');
          });
        });
      }else{
        this.$router.push('/manage/signin');
      }
    });
  }

  private onChangeImage(e: any) {
    this.image = e.target.files[0];
    this.previewImage = URL.createObjectURL(this.image);
  }

  private onChangeLogo(e: any) {
    this.logo = e.target.files[0];
    this.previewLogo = URL.createObjectURL(this.logo);
  }

  private async update(){
    if(!(await this.$refs.observer.validate()))return;
    if(!AppModule.currentUser)return;
    this.uploadImage();
    this.uploadLogo();
    db.collection("hotels").doc(AppModule.currentUser.uid).withConverter(hotelConverter).update(this.hotel).then(()=>{
      this.$notify(this.$t('notification.update'));
      db.collection("hotels").doc(AppModule.currentUser!.uid).withConverter(hotelConverter).get().then((hotel)=>{
        AppModule.setHotel(hotel.data()!);
      });
    });
  }

  private uploadImage(){
    if(!this.image.size)return;
    const storageRef = firebase.storage().ref();
    const task = storageRef.child(`/hotels/${AppModule.currentUser!.uid}/${this.hotel.id}`).put(this.image);
  }

  private uploadLogo(){
    if(!this.logo.size)return;
    const storageRef = firebase.storage().ref();
    const task = storageRef.child(`/hotels/${AppModule.currentUser!.uid}/logo`).put(this.logo);
  }

  private async getAddress(){
    const address = await zipToAddress(this.hotel.zip);
    Vue.set(this.hotel, 'address', address);
  }
}
