




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import InputWrap from '@/components/InputWrap.vue'

@Component({
  components: {
    ValidationProvider,
    InputWrap
  },
})
export default class BaseInputTextArea extends Vue {
  @Prop({ default: 'text' }) private type!: string;
  @Prop() private w!: string;
  @Prop() private rows!: string;
  @Prop() private label!: string;
  @Prop() private hint!: string;
  @Prop() private placeholder!: string;
  @Prop() private value?: string;
  @Prop() private rules?: string;
  @Prop() private vid?: string;
  private get style(): string {
    return `width: ${this.w}%`;
  }
}
